import { GetStaticProps } from 'next'
import ErrorPage from '../features/error/ErrorPage'
import {
  NowReadingDocument,
  useNowReadingQuery,
} from '../queries/generated/blog/schema'
import { initializeApollo } from '../utils/apollo'
import getGlobalData from '../utils/getGlobalData'
import { BLOG_CLIENT } from '../lib/constants/index'

export default function Custom404() {
  const { data: nowReading } = useNowReadingQuery({ context: BLOG_CLIENT })

  return <ErrorPage code={404} nowReading={nowReading} />
}

export const getStaticProps: GetStaticProps = async () => {
  const apolloClient = initializeApollo()

  await apolloClient.query({
    query: NowReadingDocument,
    context: BLOG_CLIENT,
  })

  await getGlobalData(apolloClient)

  return {
    props: {
      initialApolloState: apolloClient.cache.extract(),
      meta: {
        title: 'Код Дурова',
      },
    },
  }
}
